import { ref, unref, watchEffect } from 'vue'
import { buildVehicleAlerts } from '@/functions/checkVehicleAlerts'

export function useVehicleAlerts(vehicle) {
    const alerts = ref({})

    watchEffect(() => {
        const vehicleValue = unref(vehicle)
        if (vehicleValue) {
            alerts.value = buildVehicleAlerts(vehicleValue)
        }
    })

    return {
        alerts
    }
}
