import { ref, unref, watch } from 'vue'

export function useVehicleParameters(vehicle) {
    const commands = ref([])
    const lock_command = ref(null)
    const new_lock_panel = ref({
        visible: false,
        lock_active: false,
        panel_data: null
    })

    watch(
        vehicle,
        () => {
            // Reset the panel state at the start of each watch
            new_lock_panel.value = {
                visible: false,
                lock_active: false,
                panel_data: null
            }

            let command_source = null
            let vehicle_data = unref(vehicle)

            // Check which command source is active (dout_1 or dout_2)
            if (vehicle_data.lastpoint?.tracker?.config?.dout_1.enable === true) {
                command_source = vehicle_data.lastpoint.tracker.config.dout_1
            } else if (vehicle_data.lastpoint?.tracker?.config?.dout_2.enable === true) {
                command_source = vehicle_data.lastpoint.tracker.config.dout_2
            }

            if (command_source) {
                let lockStatus = !!(command_source.lockStatus || 1)

                new_lock_panel.value.visible = true
                // If command_source.lockStatus is 1 and command_source.value == true, then the lock is active
                // If command_source.lockStatus is 0 and command_source.value == false, then the lock is active

                // If command_source.lockStatus is 1 and command_source.value == false, then the lock is inactive
                // If command_source.lockStatus is 0 and command_source.value == true, then the lock is inactive
                new_lock_panel.value.lock_active = command_source.value === lockStatus

                new_lock_panel.value.panel_data = command_source
            }
        },
        { deep: true, immediate: true }
    )

    return {
        commands,
        lock_command,
        new_lock_panel
    }
}
