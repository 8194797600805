import Vue from 'vue'
import moment from 'moment'

const mutations = {
    // TRUCKS
    setTrucks(state, trucks) {
        state.trucks = [...trucks]
    },
    setTrailerHooked(state, trucksHooked) {
        state.truck_hooked_list = [...trucksHooked]
    },
    setTrucksRef(state, trucks) {
        state.trucksRef = { ...trucks }
    },
    setFocusTruck(state, payload) {
        state.trucks = state.trucks.map((t) => ({
            ...t,
            on_focus: t.id === payload.truck.id ? payload.on_focus : false
        }))
    },
    setActivityTruck(state, payload) {
        state.trucks = state.trucks.map((t) => ({
            ...t,
            on_activity: t.id === payload.truck.id ? payload.on_activity : false
        }))
    },
    updateTruck(state, payload) {
        let index = state.trucks.findIndex((t) => t.id === payload.truck.id)
        if (index !== -1) {
            state.trucks = [
                ...state.trucks.slice(0, index),
                { ...payload.truck },
                ...state.trucks.slice(index + 1)
            ]
        }
    },
    updateTruckProperty(state, payload) {
        let index = state.trucks.findIndex((t) => t.id === payload.truck.id)
        if (index !== -1) {
            state.trucks = [
                ...state.trucks.slice(0, index),
                {
                    ...state.trucks[index],
                    [payload.property]: payload.value
                },
                ...state.trucks.slice(index + 1)
            ]
        }
    },
    setTrucksEvents(state, events) {
        const specialVehicleEvents = [
            {
                id: 18,
                event_type: 'door_open',
                position: ['45.4383842', '10.9916215'],
                truck_id: 144101
            },
            {
                id: 19,
                event_type: 'engine_on',
                position: ['45.4384023', '10.9916124'],
                truck_id: 144101
            },
            {
                id: 20,
                event_type: 'engine_off',
                position: ['45.4384204', '10.9916033'],
                truck_id: 144101
            }
        ]
        state.trucksEvents = [...events, ...specialVehicleEvents]
    },
    setTrucksLastEventId(state, id) {
        state.trucksLastEventId = id
    },

    // BLUETOOTH DEVICES
    setBluetoothDevices(state, devices) {
        state.bluetooth_devices = [...devices]
    },

    // MAIN MAP
    setMainMapStopAutocenter(state, value) {
        state.mainMapStopAutocenter = value
    },
    setMainMapStopAutoFitBounds(state, value) {
        state.mainMapStopAutoFitBounds = value
    },
    setMainMapInstance(state, map) {
        state.mainMapInstance = map
    },
    addMainMapExtraObject(state, payload) {
        const { object, type } = payload
        const ref = object.ref
        const index = state.mainMapExtraObjects[type].findIndex((o) => o.ref === ref)

        state.mainMapExtraObjects = {
            ...state.mainMapExtraObjects,
            [type]:
                index !== -1
                    ? [
                          ...state.mainMapExtraObjects[type].slice(0, index),
                          { ...object },
                          ...state.mainMapExtraObjects[type].slice(index + 1)
                      ]
                    : [...state.mainMapExtraObjects[type], { ...object }]
        }
    },
    removeMainMapExtraObject(state, payload) {
        const { object, type } = payload
        const index = state.mainMapExtraObjects[type].indexOf(object)

        if (index !== -1) {
            state.mainMapExtraObjects = {
                ...state.mainMapExtraObjects,
                [type]: [
                    ...state.mainMapExtraObjects[type].slice(0, index),
                    ...state.mainMapExtraObjects[type].slice(index + 1)
                ]
            }
        }
    },

    // VEHICLES CROSS
    setVehiclesCrossSearch(state, search) {
        state.vehiclesCrossSearch = { ...search }
    },
    resetVehiclesCrossSearch(state) {
        state.vehiclesCrossSearch = {}
    },
    setVehiclesCrossResults(state, results) {
        state.vehiclesCrossResults = results.map((v) => ({
            ...v,
            grouped_events: v.events.reduce((acc, event, i) => {
                if (i % 2 === 0) {
                    const next_event = v.events[i + 1] || null
                    acc.push({
                        start_timestamp: event.timestamp?.$date?.$numberLong,
                        end_timestamp: next_event?.timestamp?.$date?.$numberLong || null,
                        event,
                        next_event
                    })
                }
                return acc
            }, [])
        }))
    },
    resetVehiclesCrossResults(state) {
        state.vehiclesCrossResults = []
    },

    // DRIVERS DATA
    setDrivers(state, drivers) {
        state.drivers = { ...drivers }
    },
    setDriversData(state, driversData) {
        state.driversData = { ...driversData }
    },
    updateDriversData(state, payload) {
        state.driversData = {
            ...state.driversData,
            ...payload
        }
    },

    // COMPANIES
    setCompanies(state, companies) {
        state.companies = { ...companies }
    },

    //PLAFONDS DRIVER
    setDriversPlafondData(state, { id, data }) {
        state.driversPlafondData = {
            ...state.driversPlafondData,
            [id]: {
                ...data,
                __cacheTime: moment()
            }
        }
    },
    cleanDriversPlafondCache(state) {
        state.driversPlafondData = {}
    },
    removeDriverFromDriversPlafondData(state, id) {
        const { [id]: removed, ...rest } = state.driversPlafondData
        state.driversPlafondData = rest
    },

    // POIS
    setPois(state, pois) {
        state.pois = [...pois]
    },
    addPoi(state, poi) {
        state.pois = [...state.pois, poi]
    },
    updatePoi(state, poi) {
        const index = state.pois.findIndex((p) => p.id === poi.id)
        if (index !== -1) {
            state.pois = [...state.pois.slice(0, index), { ...poi }, ...state.pois.slice(index + 1)]
        }
    },
    focusPoi(state, poi) {
        state.pois = state.pois.map((p) => ({
            ...p,
            on_focus: p.id === poi.id
        }))
    },
    removePoi(state, poi) {
        state.pois = state.pois.filter((p) => p.id !== poi.id)
    },

    // GMAPS
    setGmaps(state, gmaps) {
        state.gmaps = { ...gmaps }
    },

    // COMPANY TAGS
    setCompanyTags(state, tags) {
        state.companyTags = [...tags]
    },
    addCompanyTag(state, tag) {
        state.companyTags = [...state.companyTags, tag]
    },
    updateCompanyTag(state, tag) {
        const index = state.companyTags.findIndex((t) => t.id === tag.id)
        if (index !== -1) {
            state.companyTags = [
                ...state.companyTags.slice(0, index),
                { ...tag },
                ...state.companyTags.slice(index + 1)
            ]
        }
    },
    removeCompanyTag(state, tag) {
        state.companyTags = state.companyTags.filter((t) => t.id !== tag.id)
    },

    // FILTERS DICTIONARY
    setFiltersDictionary(state, filters) {
        state.filtersDictionary = { ...filters }
    },
    setStationsFiltersDictionary(state, filters) {
        state.filtersDictionary = {
            ...state.filtersDictionary,
            stations: filters
        }
    },
    setParkingsFiltersDictionary(state, filters) {
        state.filtersDictionary = {
            ...state.filtersDictionary,
            parkings: filters
        }
    },

    // CONFIG
    setConfig(state, config) {
        state.config = { ...config }
    },
    setConfigProperty(state, payload) {
        state.config = {
            ...state.config,
            [payload.property]: payload.value
        }
    },
    resetConfig(state) {
        state.config = {}
    },
    setTruckFilteredOut(state, ids) {
        state.truckFilteredOut = ids ? [...ids] : []
    }
}

export default mutations
