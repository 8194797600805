export default {
    open: 'Apri',
    close: 'Chiudi',
    apply: 'Applica',
    cancel: 'Annulla',
    search: 'Cerca',
    cancel_search: 'Annulla ricerca',
    edit_search: 'Modifica ricerca',
    erase: 'Cancella',
    back_to_results: 'Torna ai risultati',
    save: 'Salva',
    retry: 'Riprova',
    click_here: 'Clicca qui',
    copy: 'Copia',
    copied: 'Copiato',
    copied_coordinates: 'Coordinate copiate',
    select: 'Seleziona',
    selected: 'Selezionato',
    selected_plural: 'Selezionati',
    deselect: 'Deseleziona',
    select_multi_vehicles: 'Seleziona uno o più veicoli',
    include: 'Includi',
    exclude: 'Escludi',
    balanced: 'Equilibrato',
    input: 'Ingresso',
    output: 'Uscita',
    verified: 'Verificato',
    empty_search: 'Nessun risultato trovato',
    read_more: 'Leggi di più',
    details: 'Dettagli',
    actual: 'Attuale',
    residual_time: 'Tempo residuo',
    next: 'Prossimo',
    previous: 'Precedente',
    menu_open: 'Apri menu',
    hide: 'Nascondi',
    show: 'Mostra',
    none: 'Nessuno',
    none_alt: 'Nessuna',
    all: 'Tutti',
    all_alt: 'Tutte',
    default: 'Predefinito',
    settings: 'Impostazioni',
    reset_settings: 'Resetta impostazioni',
    reset_settings_success: 'Impostazioni resettate con successo',
    multiselect_default_value: {
        singular: 'Valore selezionato:',
        plural: 'Valori selezionati:'
    },
    available: 'Disponibile',
    unavailable: 'Non disponibile',
    not_available: 'Non disponibile',
    not_available_plural: 'Non disponibili',
    from: 'dal',
    to: 'al',
    of: 'di',
    yes: 'Si',
    no: 'No',
    minutes: 'Minuti',
    hours: 'Ore',
    hour: 'Ora',
    days: 'Giorni',
    day: 'Giorno',
    months: 'Mesi',
    month: 'Mese',
    years: 'Anni',
    year: 'Anno',
    days_names: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    days_names_short: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    months_names: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
    ],
    months_names_short: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic'
    ],
    areas: 'Aree',
    area: 'Area',
    time: 'Tempo (min)',
    time_label: 'Tempo',
    cost: 'Costo',
    toll_cost: 'Costo pedaggio',
    km_cost: 'Costo al km',
    hourly_cost: 'Costo orario',
    working_cost: 'Costo uomo totale',
    total_cost: 'Costo totale',
    expire_date: 'Data di scadenza',
    start_date: 'Data di inizio',
    recap: 'Riepilogo',
    start: 'Inizio',
    starting_point: 'Partenza',
    end: 'Fine',
    finish_point: 'Arrivo',
    duration: 'Durata',
    distance: 'Distanza',
    daily_distance: 'Km percorsi giornalieri',
    distance_total: 'Distanza totale',
    gps_distance: 'Distanza (GPS)',
    distance_gps: 'Km percorsi giornalieri (GPS)',
    pause: 'Pausa',
    segment: 'Tratta',
    start_time: 'Ora di inizio',
    end_time: 'Ora di fine',
    service_time: 'Tempo di servizio',
    waypoint_load: 'Carico a bordo',
    state: 'Stato',
    driver_statuses: {
        nessuno_stato: 'Nessuno stato',
        carico: 'Carico',
        scarico: 'Scarico',
        aggancio_semirimorchio: 'aggancio semirimorchio',
        sgancio_semirimorchio: 'sgancio semirimorchio',
        rifornimento: 'rifornimento'
    },
    violated_plafond: 'Violazione plafond',
    traffic_delay: 'Ritardo traffico',
    company: 'Azienda',
    address: 'Indirizzo',
    position: 'Posizione',
    route: 'Percorso',
    filter_date: 'Filtra per data',
    warning: 'Attenzione',
    no_warning: 'Nessun avviso',
    alerts: 'Avvisi',
    trucks_with_alerts: 'Veicoli con avvisi',
    bluetooth_devices: 'Dispositivi Bluetooth',
    no_bluetooth_devices: 'Nessun dispositivo Bluetooth trovato',
    connected: 'Connesso',
    disconnected: 'Disconnesso',
    bluetooth_types: {
        temperature_probe: 'Sonda temperatura',
        door_probe: 'Sonda porta e temperatura'
    },
    back_to_home: 'Torna alla home',
    cerca: 'Cerca',
    search_vehicle: 'Cerca veicolo',
    filter: 'Filtra',
    filter_label: 'Filtro',
    filters_label: 'Filtri',
    filter_by: 'Filtra per',
    operations: 'Operazioni',
    filter_vehicles: 'Filtra veicoli',
    filter_applied: 'Filtro applicato con successo',
    filter_canceled: 'Filtro cancellato con successo',
    order_by: 'Ordina per',
    order: 'Ordine',
    ascending: 'Crescente',
    descending: 'Decrescente',
    value: 'Valore',
    select_filter: 'Seleziona filtro',
    sort_vehicles: 'Ordina veicoli',
    sort_for_alphanumeric: 'Alfabetico',
    sort_for_lastpoint: 'Cronologico',
    sort_for_company: 'Azienda',
    sort_for_activity: 'Attività',
    company_select: 'Seleziona azienda',
    no_companies_founded: 'Nessuna azienda trovata',
    multicompany: 'Filtra per azienda',
    no_vehicles_founded: 'Nessun veicolo trovato',
    print: 'Stampa',
    export: 'Esporta',
    print_recap: 'Stampa riepilogo',
    select_date: 'Seleziona una data',
    add: 'Aggiungi',
    add_new: 'Aggiungi nuovo',
    delete: 'Elimina',
    edit: 'Modifica',
    options: 'Opzioni',
    login: "Effettua l'accesso",
    logout: 'Esci',
    logout_confirm: 'Sei sicuro di voler uscire?',
    login_button: 'Accedi',
    connect: 'Connetti',
    logged: 'Connesso',
    manage: 'Gestisci',
    loading: 'Caricamento',
    loading_timeout: 'Errore di rete, per favore ricarica la pagina o riprova più tardi',
    go_back: 'Torna indietro',
    select_coordinates: 'Seleziona coordinate',
    other: 'Altro',
    options_general: 'Generali',
    options_language: 'Lingua',
    change_interface_language: 'Seleziona lingua interfaccia',
    change_infringment_language: 'Seleziona nazione infrazioni',
    change_timezone: 'Seleziona fuso orario',
    save_language_settings: 'Salva impostazioni lingua',
    vehicle_list_options: 'Lista veicoli',
    select_view: 'Seleziona vista',
    select_fields_placeholder: 'Seleziona i campi da visualizzare',
    selected_field: 'Campo selezionato',
    selected_fields: 'Campi selezionati',
    list: 'Lista',
    table: 'Tabella',
    view_options: 'Opzioni vista',
    fluid_table: 'Larghezza dinamica',
    fluid_table_descr: 'Rendi la larghezza della tabella fluida in base ai campi selezionati',
    vehicle_fields_list: 'Quali informazioni vuoi che compaiano per ogni veicolo? (Max 3)',
    vehicle_fields_list_warning:
        'Attenzione: il campo non verrà visualizzato se non è disponibile per il veicolo',
    vehicle_identification: 'Come vuoi che il veicolo venga identificato nella lista principale?',
    apply_to_marker: 'Applica questa impostazione anche ai veicoli su mappa',
    element_icon_section: 'Icona elemento',
    element_title_section: 'Titolo elemento',
    element_fields_list_section: 'Lista campi elemento',
    element_icon_description: "Seleziona l'icona da visualizzare per l'elemento",
    element_icon: 'Icona',
    element_icon_color: 'Colore icona',
    options_map: 'Opzioni mappa',
    vehicles_clustering: 'Raggruppamento dei veicoli',
    vehicles_clustering_warning: 'Aggiornare questa impostazione richiede di ricaricare la pagina',
    options_marker: 'Opzioni marker',
    size: 'Dimensione',
    font_size: 'Dimensione testo',
    font_color: 'Colore testo',
    background_color: 'Colore sfondo',
    opacity: 'Trasparenza',
    color: 'Colore',
    small: 'Piccolo',
    medium: 'Medio',
    large: 'Grande',
    additional_info: 'Informazioni aggiuntive',
    analysis_options: 'Analisi percorso',
    options_chart: 'Opzioni grafico',
    options_timeline: 'Opzioni timeline',
    hide_timeline: 'Nascondi timeline',
    bind_colors: 'Associa colori',

    // Other
    map: 'Mappa',
    silica: 'Silica',
    google: 'Google',
    google_satellite: 'Google Satellitare',
    sandbox: 'Sandbox',
    silkysand: 'Silkysand',
    gravelpit: 'Gravelpit',
    open_street_maps: 'Open Street Maps',
    arcgis_satellite: 'ArcGIS Satellite',
    traffic: 'Traffico',
    traffic_anomalies: 'Incidenti e anomalie',
    heavy_vehicles_ban: 'Divieti veicoli pesanti',
    driver: 'Autista',
    no_driver: 'Nessun autista',
    drivers: 'Autisti',
    driver_card: 'Carta autista',
    name: 'Nome',
    surname: 'Cognome',
    driver_activity: 'Attività autista',
    driver_status: 'Stato autista',
    drivers_statuses: 'Stati autista',
    activity_type: 'Tipo di attività',
    activity: 'Attività',
    no_activity: 'Nessuna attività',
    driver_at_rest: 'Autista a riposo',
    driver_available: 'Autista in disponibilità',
    driver_at_work: 'Autista al lavoro',
    driver_driving: 'Autista in guida',
    inactive_vehicle: 'Veicolo inattivo',
    last_driver_detected: 'Ultimo autista rilevato',
    rest: 'Riposo',
    work: 'Lavoro',
    driving: 'Guida',
    availability: 'Disponibilità',
    codriving: 'Guida in equipaggio',
    codriver: 'Equipaggio',
    break: 'Pausa sessione',
    daily_rest: 'Riposo giornaliero',
    violation: 'Violazione',
    to_begin_within: 'Da iniziare entro',
    to_end_within: 'Da terminare entro',
    after_total_time_travel: 'Dopo un totale di viaggio di',
    after_total_distance_travel: 'Dopo una totale di distanza percorsa di',
    residual_commitment_type: 'Tipo di impegno residuo',
    residual_commitment: 'Impegno residuo',
    commitment_hours: 'Ore di impegno',
    commitment_hours_description:
        'Trova tutti i veicoli i cui autisti hanno un impegno residuo superiore a quello indicato',
    driver_plafond: 'Plafond autista',
    residual_driving: 'Guida residua',
    residual_bonuses: 'Bonus residui',
    consider_bonuses: 'Il tempo viene calcolato considerando i bonus residui',
    current_activity: 'Attività corrente',
    next_activity: 'Prossima attività',
    view_plafond_detail: 'Visualizza dettaglio plafond',
    card: 'Carta',
    driver_change: 'Cambio autista',
    shared_links: 'Link condivisi',
    gf: 'POI Rilevati',
    analyze: 'Analizza',
    plan: 'Pianifica',
    vehicle: 'Veicolo',
    vehicles: 'Veicoli',
    vehicle_info: 'Informazioni veicolo',
    vehicle_type: 'Tipo veicolo',
    vehicle_body: 'Corpo veicolo',
    truck_status: 'Stato veicolo',
    brand: 'Marca',
    model: 'Modello',
    no_card: 'Carta non inserita',
    no_card_driving: 'Guida senza carta',
    speed: 'Velocità',
    tacho_speed: 'Velocità (tachigrafo)',
    vehicle_hooked: 'Veicolo agganciato',
    truck_hooked_text: 'Questo veicolo è agganciato al seguente rimorchio: ',
    trailer_hooked_text: 'Questo rimorchio è agganciato al seguente veicolo: ',
    no_driver_rfid_info: "La carta conducente non è registrata nell'anagrafica autisti",
    gps_speed: 'Velocità (GPS)',
    level: 'Livello',
    battery: 'Batteria',
    low_battery_level: 'Livello batteria basso',
    fuel: 'Carburante',
    fuel_level: 'Livello carburante',
    low_fuel_level: 'Carburante scarso',
    consumption: 'Consumo',
    consumptions: 'Consumi',
    fuel_consumption: 'Consumo carburante',
    fuel_avg_consumption: 'Media consumo carburante giornaliera',
    altitude: 'Altitudine',
    weight: 'Peso',
    lastpoint_weight: 'Peso (ultimo punto)',
    vehicle_weight: 'Peso (t)',
    vehicle_length: 'Lunghezza (m)',
    vehicle_width: 'Larghezza (m)',
    vehicle_height: 'Altezza (m)',
    odometer: 'Odometro',
    odometer_tacho: 'Odometro (tachigrafo)',
    odometer_gps: 'Odometro (GPS)',
    gps_odometer: 'Odometro (GPS)',
    last_point: 'Ultimo punto',
    moving: 'In movimento',
    stopped: 'Fermo',
    engine_on: 'Motore acceso',
    ignition: 'Quadro acceso',
    engine_off: 'Motore spento',
    engine_on_off: 'Accensione motore',
    ignition_on: 'Quadro acceso',
    ignition_off: 'Quadro spento',
    device: 'Dispositivo',
    plate: 'Targa',
    chassis: 'Telaio',
    status: 'Status',
    custom_id: 'Nome personalizzato',
    heading: 'Direzione',
    rpm_engine: 'Giri Motore',
    pto_active: 'PTO attivo',
    pto_state: 'Stato PTO',
    device_timestamp: 'Timestamp',
    info: 'Info',
    solar_day_report: 'Report giornata solare',
    telemetry: 'Telemetria',
    parameters: 'Parametri',
    engine_coolant_temp: 'Temperatura motore',
    engine_rpm: 'Giri motore',
    dallas_temp_1: 'Temperatura 1',
    dallas_temp_2: 'Temperatura 2',
    dallas_temp_id_1: 'ID Temperatura 1',
    dallas_temp_id_2: 'ID Temperatura 2',
    digital_input_1: 'Ingresso digitale 1',
    digital_input_2: 'Ingresso digitale 2',
    digital_input_3: 'Ingresso digitale 3',
    digital_input_4: 'Ingresso digitale 4',
    digital_input_5: 'Ingresso digitale 5',
    battery_level: 'Livello batteria',
    adblue_liters: 'Litri Adblue',
    adblue_level: 'Livello Adblue',
    gnss_status: 'Stato GNSS',
    gnss_status_values: [
        'Spento',
        'Accesa, assenza GPS',
        'Accesa, assenza di satelliti',
        'Accesa, con satelliti',
        'Accesa, in sleep',
        'Stato di protezione'
    ],
    gnss_off: 'GNSS spento',
    gnss_on_fix: 'GNSS acceso, con satelliti',
    gnss_on_without_fix: 'GNSS acceso, assenza di satelliti',
    gnss_on_sleep: 'GNSS acceso, in sleep',
    gnss_on_protection: 'GNSS acceso, stato di protezione',
    gsm_signal: 'Segnale GSM',
    tacho_source: 'Sorgente tachigrafo',
    external_voltage: 'Alimentazione',
    tacho_overspeed: 'Overspeed tachigrafo',
    no_points_title: 'Ultima attività da più di 24 ore',
    no_points: 'Questo veicolo non manda punti da più di 24 ore',
    clutch: 'Frizione',
    clutch_switch: 'Interruttore frizione',
    brake_pedal: 'Pedale freno',
    accelerator_pedal: 'Pedale acceleratore',
    pedal_released: 'Pedale rilasciato',
    pedal_pressed: 'Pedale premuto',
    data_mode: 'Modalità dati',
    home_stop: 'Fermo in sede',
    home_moving: 'In movimento in sede',
    roaming_stop: 'Fermo in roaming',
    roaming_moving: 'In movimento in roaming',
    unknown_stop: 'Fermo sconosciuto',
    unknown_moving: 'In movimento sconosciuto',
    engine_coolant_temperature: 'Temperatura liquido raffreddamento',
    engine_temperature: 'Temperatura motore',
    cruise_control_active: 'Cruise control attivo',
    adblue_off: 'Adblue spento',
    adblue_red: 'Adblue rosso',
    adblue_yellow: 'Adblue giallo',
    adblue_info: 'Adblue info',
    charger_notconnected: 'Caricatore non collegato',
    charger_connected: 'Caricatore collegato',
    tacho_unknown: 'Sconosciuto',
    tacho_kline: 'K-Line',
    tacho_allcan: 'BUS-CAN',
    tacho_can: 'BUS-CAN',
    tacho_fms: 'FMS',
    lock_command: 'Blocco accensione',
    vehicle_not_found: 'Il veicolo a cui stai tentando di accedere non è disponibile',
    vehicle_with_lock_commands: 'Veicolo con comandi di blocco',
    lock: 'Blocca',
    unlock: 'Sblocca',
    send: 'Invia',
    command_sent: 'Comando inviato con successo',
    turn_on: 'Attiva',
    turned_on: 'Attivo',
    turn_off: 'Disattiva',
    turned_off: 'Disattivo',
    confirm_send_command: 'Confermi di voler inviare il comando?',
    temperature: {
        title: 'Sonde temperatura',
        probe: ['Sonda 1', 'Sonda 2', 'Sonda 3', 'Sonda 4', 'Sonda 5', 'Sonda 6']
    },
    no_power_supply: 'Alimentazione assente',
    no_gps: 'GPS assente',
    tacho_clock_from_updated_error: 'Errore sincronizzazione orologio del tachigrafo',
    tacho_clock_from_device_error: 'Errore sincronizzazione tra tachigrafo e centralina',
    device_timestamp_error: 'Errore data centralina',
    select_color_mode: 'Seleziona modalità colore',
    light_mode: 'Modalità chiara',
    dark_mode: 'Modalità scura',
    color_blind_mode: 'Modalità daltonici',
    color_blind_dark_mode: 'Modalità scura daltonici',
    authorized: 'Autorizzato',
    logged_out: 'Uscito',
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Veicoli in evidenza',
            search_trucks_title: 'Cerca veicoli',
            search_trucks_tags_title: 'Tag associati',
            search_trucks_status_title: 'Attività veicolo',
            search_trucks_driver_status_title: 'Attività autista',
            search_near_trucks_title: 'Trova veicoli vicini'
        },
        detailspanel: {
            bind_vehicle: 'Associa veicolo',
            add_new_tag: 'Aggiungi come nuovo tag',
            select_new_tag: 'Seleziona o cerca un tag',
            binded_tags: 'Tag associati',
            binded_tag: 'Tag associato'
        },
        analyzer: {
            vehicle_activity_analisys: 'Analisi attività veicolo',
            timeline_separator: 'Raggruppa per',
            loading_error: 'Si è verificato un errore durante il caricamento dei dati'
        },
        search_resources: {
            title: 'Cerca risorse',
            description:
                'Trova le stazioni di rifornimento, i parcheggi e le aree raggiungibili più vicine al tuo veicolo.'
        }
    },
    analisys: {
        title: 'Analisi percorso',
        select_telemetries: "Seleziona le telemetrie da visualizzare nell'analisi percorso",
        select_telemetries_description:
            "E' possibile selezionare un massimo di 4 telemetrie da visualizzare",
        chart_groups: {
            movement_data: 'Dati di movimento',
            engine_data: 'Dati motore',
            vehicle_data: 'Dati veicolo',
            sensors_data: 'Dati sensori',
            driver_data: 'Dati autista',
            fuel_data: 'Dati carburante'
        },
        no_data: 'Nessun dato disponibile',
        no_data_description:
            'Non ci sono dati di percorso da visualizzare per questo veicolo o per il periodo selezionato',
        no_data_contact_support: 'Contatta il supporto clienti per ulteriori informazioni'
    },
    planner: {
        user_config: 'Configurazioni utente',
        no_user_config: 'Non ci sono configurazioni utente da visualizzare',
        freight_access: 'Accesso alle borse carico',
        vehicle_actual_position: 'Posizione attuale veicolo',
        plan_route: 'Pianifica percorso',
        select_on_map: 'Seleziona un punto sulla mappa',
        select_on_map_option: 'Seleziona sulla mappa',
        fill_route_info: 'Compila le informazioni del percorso',
        fill_resources: 'Seleziona il punto da aggiungere come tappa',
        fill_waypoints: 'Seleziona le tappe',
        load_breaks: 'Carica le pause',
        load_alternative_routes: 'Carica percorsi alternativi',
        savedroutes: {
            my_routes: 'I miei percorsi',
            saved_routes: 'Percorsi salvati',
            no_saved_routes: 'Non hai ancora salvato nessun percorso',
            success_saved_route: 'Il tuo percorso è stato salvato con successo',
            error_saved_route: 'Si è verificato un errore durante il salvataggio del percorso',
            success_deleted_route: 'Il tuo percorso è stato eliminato con successo',
            error_deleted_route: "Si è verificato un errore durante l'eliminazione del percorso"
        },
        resources: {
            area_search: 'Cerca nel raggio di',
            station_services: 'Comprensivi di questi servizi',
            parking_services: 'Servizi parcheggio',
            select_rating: 'Seleziona il punteggio',
            select_security: 'Seleziona la sicurezza',
            empty_search: 'La ricerca non ha trovato alcun elemento da mostrare',
            delivery_nation: 'Nazione di consegna',
            fai_featured: 'Convenzionato Lumesia',
            self_service: 'Self Service',
            served: 'Servito',
            total_parking_spots: 'Totale posti',
            rating: 'Rateo',
            reviews: 'Recensioni',
            security: 'Sicurezza',
            price_per_night: 'Prezzo per notte'
        },
        emissions_labels: 'Emissioni',
        emissions: {
            fuelconsumption: 'Consumo carburante',
            hydrocarbons: 'Idrocarburi',
            methane: 'Metano',
            hydrocarbonsexceptmethane: 'Idrocarburi eccetto metano',
            carbonmonoxide: 'Monossido di carbonio',
            carbondioxide: 'Diossido di carbonio',
            fossilcarbondioxide: 'Diossido di carbonio fossile',
            sulphurdioxide: 'Diossido di zolfo',
            nitrogenoxides: 'Ossidi di azoto',
            nitrogendioxide: 'Diossido di azoto',
            nitrousoxide: 'Ossido nitroso',
            ammonia: 'Ammoniaca',
            benzene: 'Benzene',
            particles: 'Particelle',
            numberofparticles: 'Numero di particelle'
        },
        plafond_configuration: {
            title: 'Configurazione plafond',
            driving_time_regulation: 'Tempo di guida',
            last_time_the_driver_worked: 'Ultima attività autista',
            eu_driving_time_regulation_for_single_day: 'Singolo giorno',
            eu_driving_time_regulation_for_single_long_day: 'Singolo giorno (con bonus)',
            eu_driving_time_regulation_for_multiple_day: 'Più giorni',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Più giorni (con equipaggio)',
            accumulated_driving_time_since_lastbreak: "Ore di guida dall'ultima pausa",
            accumulated_driving_time_since_lastdailyrest:
                "Ore di guida dall'ultimo riposo giornaliero",
            accumulated_travel_time_since_lastdailyrest:
                "Ore di viaggio dall'ultimo riposo giornaliero"
        },
        set_route: 'Imposta percorso',
        select_profile: 'Seleziona profilo',
        select_co2_class: 'Seleziona classe di emissioni',
        class: 'Classe',
        billed_roads: 'Strade a pagamento',
        waypointevent: 'Tappa',
        waypoints_label: 'Tappe',
        add_waypoint: 'Aggiungi tappa',
        delete_waypoint: 'Elimina tappa',
        select_waypoint: 'Seleziona tappa',
        select_resource: 'Seleziona il punto da aggiungere come tappa',
        last_waypoint: 'Ultima tappa',
        waypoints_check: 'Ci sono delle tappe da completare',
        waypoints_required: 'Sono necessarie almeno 2 tappe',
        countryevent: 'Stato',
        tollevent: 'Casello',
        event: 'Evento',
        alpega: 'Alpega',
        alpega_login: 'Login Alpega',
        alpega_searches: 'Le tue ricerche',
        timocom: 'Timocom',
        pickup: 'Ritiro',
        delivery: 'Consegna',
        extimated_cost: 'Costo stimato',
        calculate_route: 'Calcola percorso',
        calculate_isocrona: 'Calcola isocrona',
        complete_step_2: 'Completa lo step 2',
        reset_route: 'Nuovo percorso',
        route_error: 'Si è verificato un errore durante il calcolo del percorso',
        edit_route: 'Modifica percorso',
        save_route: 'Salva percorso',
        delete_route: 'Elimina percorso',
        route_name: 'Nome percorso',
        override_route: 'Sovrascrivi percorso',
        save_as_new_route: 'Salva nuovo percorso',
        calculate_route_for: 'Calcola percorso per',
        ptvexport: 'Esporta in PTV Navigator',
        isocrona: 'Aree raggiungibili',
        reset_isocrona: 'Resetta aree raggiungibili',
        search_location: 'Cerca località',
        search_station: 'Cerca distributore',
        search_parking: 'Cerca parcheggio',
        search_poi: 'Cerca POI',
        include_plafond: 'Includi plafond autista nel calcolo del percorso',
        include_plafond_near_vehicles:
            'Trova i veicoli con autisti aventi un impegno residuo sufficiente ad arrivare alla località cercata',
        violated_plafond: "Il percorso selezionato viola il plafond dell'autista"
    },
    sharedlinks: {
        back_to_links: 'Torna ai link',
        create_link: 'Crea link',
        update_link: 'Aggiorna link',
        edit_link: 'Modifica link',
        update_link_expire_date: 'Aggiorna data di scadenza',
        delete_link: 'Elimina link',
        delete_link_confirm: 'Eliminare il link selezionato?',
        all_your_shared_links: 'Tutti i tuoi link condivisi',
        no_shared_links: 'Non hai ancora condiviso nessun veicolo',
        vehicles_link: 'Link per i veicoli',
        select_share_vehicles: 'Seleziona veicoli da condividere',
        select_start_expire_date: 'Seleziona data di inizio e di scadenza',
        created_link_error: 'Errore durante la creazione del link',
        created_link_success: 'Link creato con successo',
        updated_link_error: "Errore durante l'aggiornamento del link",
        updated_link_success: 'Link aggiornato con successo',
        service_description:
            'Condividi questo link per mostrare il tracciamento dei veicoli selezionati',
        link_preview: 'Anteprima del link',
        share_single_vehicle: 'Condividi la posizione di questo veicolo',
        track_vehicle_position: 'Traccia la posizione dei veicoli selezionati',
        start_date_warning: 'Non è possibile modificare la data di inizio',
        start_date_condition: 'Se vuoi modificare la data di inizio, devi creare un nuovo link',
        expire_date_description:
            'Indica una data di scadenza, oltre il quale questo link non sarà più disponibile',
        start_expire_date_description:
            'Indica una data di iniziale, a partire dal quale questo link sarà disponibile, e una data di scadenza, oltre il quale questo link non sarà più disponibile:'
    },
    viewsharedlink: {
        invalid_date_range: 'Il range di date che hai selezionato non è valido per questo link',
        too_early_link: 'Il link a cui stai tentando di accedere non è ancora diponibile',
        too_late_link: 'Il link a cui stai tentando di accedere non esiste più',
        show_route: 'Mostra percorso'
    },
    tags: {
        manage_tags: 'Gestisci tags',
        add_tag: 'Aggiungi tag',
        add_tag_placeholder: 'Inserisci il nome del tag',
        add_tag_error: "Errore durante l'aggiunta del tag",
        add_tag_success: 'Tag aggiunto con successo',
        delete_tag: 'Elimina tag',
        delete_tag_confirm: 'Eliminare il tag selezionato?',
        delete_tag_error: "Errore durante l'eliminazione del tag",
        delete_tag_success: 'Tag eliminato con successo',
        update_tag: 'Aggiorna tag',
        update_tag_error: "Errore durante l'aggiornamento del tag",
        update_tag_success: 'Tag aggiornato con successo',
        all_your_tags: 'Tutti i tuoi tag',
        no_tags: 'Non hai ancora creato nessun tag',
        tag_name: 'Nome tag'
    },
    manage_poi: {
        title: 'Gestisci POI',
        your_pois: 'I tuoi POI',
        add_poi: 'Aggiungi POI',
        no_pois: 'Non hai ancora creato nessun POI',
        fields: {
            name: 'Nome',
            type: 'Tipo',
            color: 'Colore',
            note: 'Note',
            address: 'Cerca indirizzo',
            latitude: 'Latitudine',
            longitude: 'Longitudine',
            radius: 'Raggio di ricerca',
            radius_description: 'Per abilitare il geofencing impostare un valore maggiore di zero'
        },
        types_poi: {
            all: 'Tutti',
            headquarter: 'Sede',
            truck_washes: 'Autolavaggi autotreni',
            blacklist: 'Blacklist',
            end_of_the_line: 'Capolinea',
            customers: 'Clienti',
            stops: 'Fermate',
            suppliers: 'Fornitori',
            interports: 'Interporti',
            markets: 'Mercati',
            large_square: 'Piazzali',
            poi_distributors: 'POI Distributori',
            poi_workshops: 'POI Officine',
            harbors: 'Porti',
            restaurants: 'Ristoranti',
            stations: 'Stazioni'
        },
        messages: {
            add_poi_error: "Errore durante l'aggiunta del POI",
            add_poi_success: 'POI aggiunto con successo',
            update_poi_error: "Errore durante l'aggiornamento del POI",
            update_poi_success: 'POI aggiornato con successo',
            delete_poi_error: "Errore durante l'eliminazione del POI",
            delete_poi_success: 'POI eliminato con successo'
        }
    },
    vehicles_cross: {
        title: 'Passaggio veicoli',
        fields: {
            address: 'Indirizzo',
            radius: 'Raggio',
            time_interval: 'Intervallo di tempo',
            time_interval_description: 'Seleziona un intervallo di tempo massimo di 24 ore'
        },
        messages: {
            max_time_interval: "L'intervallo di tempo massimo è di 24 ore",
            error_searching: 'Si è verificato un errore durante la ricerca dei veicoli',
            searching: 'Ricerca in corso...',
            long_loading: 'La ricerca potrebbe richiedere alcuni minuti, attendere prego...',
            no_vehicles_cross: 'Nessun veicolo trovato'
        }
    },
    plafonds: {
        session: 'Pausa di sessione',
        break: 'Pausa',
        drive_occurring: "E' in corso un Periodo di Guida:",
        drive_max_label: 'Tempo massimo di guida',
        drive_max_value: '4h 30m',
        drive_done: 'Guida effettuata',
        session_title: 'Informazioni sulla Pausa Frazionata',
        session_tooltip: "E' possibile effettuare una Pausa Frazionata",
        session_done: "E' stata rilevata una pausa di almeno 15 minuti.",
        session_info: 'Già effettuata la prima parte di una Pausa Frazionata',
        session_descr:
            "Ricordiamo che per le vigenti normative è possibile effettuare una pausa di 15'+30' o di 15'+15'+15' (in base al tipo di trasporto effettuato).",
        session_choice:
            "E' possibile effettuare almeno <b>30m</b> (trasporto merci) o <b>15m + 15m</b> (trasporto persone) entro le prossime",
        brake_start: 'Inizio pausa',
        brake_done: 'Pausa effettuata',
        daily: 'Giornaliera',
        weekly: 'Settimanale',
        driver_in_drive: 'Stai guidando da',
        driver_in_rest: 'Riposo residuo',
        driver_in_work: 'Lavoro',
        driver_in_available: 'Disponibilità',
        driver_in_availability: 'Disponibilità',
        current_activity: 'Attività corrente',
        mini_plafond: 'Mini-Plafond',
        last_update: 'Ultimo aggiornamento',
        panel_title: 'Pannello Plafond',
        residual_commitment: 'Impegno residuo',
        daily_driving: 'Guida giornaliera',
        weekly_guide: 'Guida settimanale',
        biweekly_guide: 'Guida bi-settimanale',
        last_rest: 'Ultimo riposo settimanale effettuato',
        minimal_duration_next: 'Durata minima prossimo',
        top_start_until: 'Da iniziare entro il',
        reduced_weekly_rest: 'Riposi settimanali ridotti da compensare',
        time_to_compensate: 'Tempo da compensare',
        time_to_compensate_until: 'Da compensare entro il',
        footer_message: '* il tempo viene calcolato considerando i bonus residui',
        driver_in_block: 'Plafond autista esaurito',
        résidu_commitment: "Residuo dell'impegno",
        week_guide: 'Guida hebdomadaire',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compenser'
    },
    error_pages: {
        error_403: {
            title: 'Errore 403',
            description:
                'Gentile cliente, ti informiamo che la tua utenza non è attualmente configurata per accedere a questo servizio. Per ricevere informazioni sul servizio stesso',
            retry_login: "Riprova l'accesso",
            contact_us: 'Contattaci'
        }
    },
    ptv: {
        maxpermittedweight: 'Peso massimo',
        minpermittedweight: 'Peso minimo',
        zonename: 'Zona',
        unauthorizedtype: 'Codice non autorizzato',
        maxemissionstandardeu: 'Classe di emissione',
        fueltype: 'Tipo carburante',
        loadtype: 'Tipo di trasporto',
        opening: 'Passaggio',
        maxheight: 'Altezza massima',
        maxweight: 'Peso massimo',
        maxwidth: 'Larghezza massima',
        maxlength: 'Lunghezza massima',
        absolutespeed: 'Velocità',
        hazardoustowaters: "Divieto per veicoli con merce pericolosa per l'acqua",
        hazardousgoods: 'Divieto per veicoli con merce pericolosa',
        combustibles: 'Divieto per veicoli che trasportano carburante',
        maxaxleload: 'Peso massimo agli assi',
        totalpermittedweight: 'Peso massimo',
        tunnelrestriction: 'Codice restrizione tunnell',
        kingpintorearaxle: 'Distanza da kingpin a asse',
        numberofaxles: 'Numero di assi',
        length: 'Tratta',
        delay: 'Ritardo',
        message: {
            text: 'Messaggio'
        }
    },
    options_interface: 'Interfaccia delle opzioni'
}
